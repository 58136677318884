import {
	setweb,
	gettoken,
	homedatas,
	homeschool,
	homescheme,
	circlebtn,
	advert,
	users,
	usersrank,
	usersmyattent,
	usersmyfans,
	usersremovefans,
	userscoins,
	usersprofile,
	usersaveprofile,
	cashucash,
	cashinfo,
	cashlist,
	circlepublish,
	circlelist,
	indexlogin,
	usercrony,
	circleinfo,
	circlelistcommt,
	circlecommt,
	circlecommtinfo,
	circlecommtlist,
	indexhome,
	indexmadvert,
	shopscate,
	shopcircle,
	shopcircleinfo,
	shopindex,
	shopcommt,
	shopdelecommt,
	shopchuildinfo,
	circlescate,
	cirledetle,
	userayyent,
	uploadoss,
	cashsuccc,
	editcircle,
	delcircle,
	delpic,
	setwebs,
	wxpay,
	indexinfo,
	modifyedit,
	indexhomlep,
	advertdetail,
	modifydelete,
	shopperdect,
	shoplist,
	shopexam,
	indexdelpic,
	usercheck,
	jsdk,
	cirrank,
	checkdata,
	userbrows,
	usersign,
	poster,
	indextop,
	homeIndex,
	isfollow,
	praise,
	maildetail,
	maillist,
	pluglist,
	plugdetail,
	drawlist,
	drawrecord,
	drawpiece,
	list_order,
	order_detail,
	settle,
	addressList,
	editAddress,
	single_order,
	raffle
} from './task/index.js'
export default {
	setweb,
	gettoken,
	homedatas,
	homeschool,
	homescheme,
	circlebtn,
	advert,
	users,
	usersrank,
	usersmyattent,
	usersmyfans,
	usersremovefans,
	userscoins,
	usersprofile,
	usersaveprofile,
	cashucash,
	cashinfo,
	cashlist,
	circlepublish,
	circlelist,
	indexlogin,
	usercrony,
	circleinfo,
	circlelistcommt,
	circlecommt,
	circlecommtinfo,
	circlecommtlist,
	indexhome,
	indexmadvert,
	shopscate,
	shopcircle,
	shopcircleinfo,
	shopindex,
	shopcommt,
	shopdelecommt,
	shopchuildinfo,
	circlescate,
	cirledetle,
	userayyent,
	uploadoss,
	cashsuccc,
	editcircle,
	delcircle,
	delpic,
	setwebs,
	wxpay,
	indexinfo,
	modifyedit,
	indexhomlep,
	advertdetail,
	modifydelete,
	shopperdect,
	shoplist,
	shopexam,
	indexdelpic,
	usercheck,
	jsdk,
	cirrank,
	checkdata,
	userbrows,
	usersign,
	poster,
	indextop,
	homeIndex,
	isfollow,
	praise,
	maildetail,
	maillist,
	pluglist,
	plugdetail,
	drawlist,
	drawrecord,
	drawpiece,
	list_order,
	order_detail,
	settle,
	addressList,
	editAddress,
	single_order,
	raffle
}
