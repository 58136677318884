import {
	createRouter,
	createWebHistory
} from 'vue-router'
import {
	Dialog
} from 'vant'
import wx from 'weixin-js-sdk'
import axios from '../utils/api/index.js'

const router = createRouter({
	history: createWebHistory(),
	routes: [{
			path: '/',
			component: () => import('@/view/HoMe'),
			meta: {
				title: '小猋校园',
				keepAlive: true,
				saveSrollTop: true
			}
		},
		{
			path: '/WeChat',
			component: () => import('@/view/WeChat'),
			meta: {
				title: '授权'
			}
		},
		{
			path: '/loginWx',
			component: () => import('@/view/loginWx'),
			meta: {
				title: '登录'
			}
		},
		{
			path: '/UniversalWall',
			component: () => import('@/view/UniversalWall'),
			meta: {
				title: '表白墙',
				keepAlive: true,
				saveSrollTop: true
			}
		},
		{
			path: '/GetFriend',
			component: () => import('@/view/GetFriend'),
			meta: {
				title: '捞个好友'
			}
		},
		{
			path: '/MyData',
			component: () => import('@/view/MyData'),
			meta: {
				title: '我的'
			}
		},
		{
			path: '/ChooseScholl',
			component: () => import('@/view/Schooldetail/ChooseScholl'),
			meta: {
				title: '选择学校'
			}
		},
		{
			path: '/AnnounceMent',
			component: () => import('@/view/Schooldetail/AnnounceMent'),
			meta: {
				title: '公告详情'
			}
		},
		{
			path: '/UniverDetail',
			component: () => import('@/view/WallDetails/UniverDetail'),
			meta: {
				title: '详情'
			}
		},
		{
			path: '/lieIdle',
			component: () => import('@/view/WallDetails/lieIdle'),
			meta: {
				title: '发布闲置'
			}
		},
		{
			path: '/PostSt',
			component: () => import('@/view/WallDetails/PostSt'),
			meta: {
				title: '发布帖子'
			}
		},
		{
			path: '/PubLish',
			component: () => import('@/view/MyList/PubLish'),
			meta: {
				title: '发布信息'
			}
		},
		{
			path: '/MySuccess',
			component: () => import('@/view/MyList/MySuccess'),
			meta: {
				title: '发布成功'
			}
		},
		{
			path: '/FolLow',
			component: () => import('@/view/MyList/FolLow'),
			meta: {
				title: '我的关注'
			}
		},
		{
			path: '/MyPost',
			component: () => import('@/view/MyList/MyPost'),
			meta: {
				title: '我的发布'
			}
		},
		{
			path: '/WithdraWal',
			component: () => import('@/view/MyList/WithdraWal'),
			meta: {
				title: '积分提现'
			}
		},
		{
			path: '/WithSuccess',
			component: () => import('@/view/MyList/WithSuccess'),
			meta: {
				title: '提现申请成功'
			}
		},
		{
			path: '/WithDetail',
			component: () => import('@/view/MyList/WithDetail'),
			meta: {
				title: '提现详情'
			}
		},
		{
			path: '/WithPost',
			component: () => import('@/view/MyList/WithPost'),
			meta: {
				title: '提现明细'
			}
		},
		{
			path: '/PointsDetails',
			component: () => import('@/view/MyList/PointsDetails'),
			meta: {
				title: '积分明细'
			}
		},
		{
			path: '/PointRanking',
			component: () => import('@/view/MyList/PointRanking'),
			meta: {
				title: '积分排行榜'
			}
		},
		{
			path: '/SchoolHome',
			component: () => import('@/view/HomeSchool/SchoolHome'),
			meta: {
				title: '大学',
				isToken: 2
			}
		},
		{
			path: '/ManageMent',
			component: () => import('@/view/HomeSchool/ManageMent'),
			meta: {
				title: '公告管理',
				isToken: 2
			}
		},
		{
			path: '/EditAnnoun',
			component: () => import('@/view/HomeSchool/EditAnnoun'),
			meta: {
				title: '编辑公告',
				isToken: 2
			}
		},
		{
			path: '/CampusWall',
			component: () => import('@/view/HomeSchool/CampusWall'),
			meta: {
				title: '校园墙',
				isToken: 2
			}
		},
		{
			path: '/CampusReview',
			component: () => import('@/view/HomeSchool/CampusReview'),
			meta: {
				title: '校园墙审核',
				isToken: 2
			}
		},
		{
			path: '/PostDetails',
			component: () => import('@/view/HomeSchool/PostDetails'),
			meta: {
				title: '帖子详情',
				isToken: 2
			}
		},
		{
			path: '/CampusInfor',
			component: () => import('@/view/HomeSchool/CampusInfor'),
			meta: {
				title: '个人信息',
				isToken: 2
			}
		},
		{
			path: '/RepLy',
			component: () => import('@/view/WallDetails/RepLy'),
			meta: {
				title: '回复'
			}
		},
		{
			path: '/RelyDoble',
			component: () => import('@/view/HomeSchool/RelyDoble'),
			meta: {
				title: '回复',
				isToken: 2
			}
		},
		{
			path: '/SetNot',
			component: () => import('@/view/SetNot'),
			meta: {
				title: '禁用'
			}
		},
		{
			path: '/AbouTs',
			component: () => import('@/view/MyList/AbouTs'),
			meta: {
				title: '我的消息'
			}
		},
		{
			path: '/WxGrant',
			component: () => import('@/view/HomeSchool/WxGrant'),
			meta: {
				title: '绑定微信',
				isToken: 2
			}
		},
		{
			path: '/AgreeMent',
			component: () => import('@/view/MyList/AgreeMent'),
			meta: {
				title: '用户协议'
			}
		},
		{
			path: '/HomeAdrve',
			component: () => import('@/view/HomeSchool/HomeAdrve'),
			meta: {
				title: '公告详情',
				isToken: 2
			}
		},
		{
			path: '/ContactUs',
			component: () => import('@/view/MyList/ContactUs'),
			meta: {
				title: '联系我们'
			}
		},
		{
			path: '/NotPost',
			component: () => import('@/view/HomeSchool/NotPost'),
			meta: {
				title: '未审核',
				isToken: 2
			}
		},
		{
			path: '/NewDome',
			component: () => import('@/view/NewDome'),
			meta: {
				title: '测试demo'
			}
		},
		{
			path: '/ProMition',
			component: () => import('@/view/MyList/ProMition'),
			meta: {
				title: '邀请好友'
			}
		},
		{
			path: '/PcItem',
			component: () => import('@/view/PcItem'),
			meta: {
				title: '小猋校园'
			}
		},
		{
			path: '/WallNew',
			component: () => import('@/view/WallNew'),
			meta: {
				title: '测试页面'
			}
		},
		{
			path: '/NewShow',
			component: () => import('@/view/MyList/NewShow'),
			meta: {
				title: '关注公众号'
			}
		},
		{
			path: '/PddlLst',
			component: () => import('@/view/Schooldetail/PddlLst'),
			meta: {
				title: '多多省钱'
			}
		},
		{
			path: '/MakeMoney',
			component: () => import('@/view/HomeSchool/MakeMoney'),
			meta: {
				title: '赚点钱'
			}
		},
		{
			path: '/AboutDetail',
			component: () => import('@/view/MyList/AboutDetail'),
			meta: {
				title: '消息详情'
			}
		},
		{
			path: '/PddDetail',
			component: () => import('@/view/Schooldetail/PddDetail'),
			meta: {
				title: '拼多多详情'
			}
		},
		{
			path: '/CjBack',
			component: () => import('@/view/Schooldetail/CjBack'),
			meta: {
				title: '积分抽奖'
			}
		},
		{
			path: '/RuleList',
			component: () => import('@/view/Schooldetail/RuleList'),
			meta: {
				title: '活动规则'
			}
		},
		{
			path: '/CjList',
			component: () => import('@/view/Schooldetail/CjList'),
			meta: {
				title: '抽奖记录'
			}
		},
		{
			path: '/MyReord',
			component: () => import('@/view/Schooldetail/MyReord'),
			meta: {
				title: '我的奖品'
			}
		},
		{
			path: '/MyOrders',
			component: () => import('@/view/Schooldetail/MyOrders'),
			meta: {
				title: '我的订单'
			}
		},
		{
			path: '/OrderDetails',
			component: () => import('@/view/Schooldetail/OrderDetails'),
			meta: {
				title: '订单详情'
			}
		},
		{
			path: '/CherrOrder',
			component: () => import('@/view/Schooldetail/CherrOrder'),
			meta: {
				title: '订单结算'
			}
		},
		{
			path: '/ShippingAddress',
			component: () => import('@/view/Schooldetail/ShippingAddress'),
			meta: {
				title: '收货地址'
			}
		},
		{
			path: '/AddAddress',
			component: () => import('@/view/Schooldetail/AddAddress'),
			meta: {
				title: '添加收货地址'
			}
		}
	],
	scrollBehavior(to, from, savedPosition) {
		console.log(savedPosition,"www")
		localStorage.setItem('yy',savedPosition.top) 
		if (savedPosition) {
			return savedPosition;
		} else {
			if (from.meta.saveSrollTop) {
				from.meta.savedPosition = document.documentElement.scrollTop || document.body.scrollTop;
			}
			return {
				x: 0,
				y: to.meta.savedPosition || 0
			};
		}
	}
})
// router.beforeEach((to, from, next)=>{
// 	document.title = to.meta.title ? to.meta.title : '';
// 	next();
// })
router.beforeEach((to, from, next) => {
	console.log(to,from,next,"000")
	document.title = to.meta.title ? to.meta.title : '';
	let shopToken = localStorage.getItem('shopToken');
	if (to.meta.isToken == 2) {
		if (shopToken == '' || shopToken == null || shopToken == "undefined") {
			setTimeout(() => {
				Dialog.alert({
					title: '需要登录才可以查看~',
					width:'320px'
				}).then(() => {
					// on close
					next({
						path: '/loginWx'
					})
				});
			}, 500)
			return;
		} else {
			next();
		}
	} else {
		next();
	}
});
router.afterEach(() => { // 每个页面都调用一遍都重新拉取config
	let u = navigator.userAgent
	let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
	if (!isAndroid) { // IOS
		if (window.entryUrl == '' || window.entryUrl == undefined) {
			var url = location.href.split('#')[0]
			window.entryUrl = url
		}
	} else { // 安卓
		window.entryUrl = location.href.split('#')[0]
	}
	var parmsname = {
		pageurl: encodeURIComponent(window.entryUrl)
	}
	axios.jsdk(parmsname).then((res) => {
		if (res.data.error_code == 200) {
			wx.config({
				debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
				appId: res.data.data.appid, // 必填，公众号的唯一标识
				timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
				nonceStr: res.data.data.noncestr, // 必填，生成签名的随机串
				signature: res.data.data.signature, // 必填，签名，见附录1
				jsApiList: ["scanQRCode", "openLocation", "getLocation",
					"updateAppMessageShareData", "updateTimelineShareData", 'chooseWXPay'
				] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
			});
		}
	})
})
export default router;
