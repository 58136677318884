<template>
	<!-- <router-view></router-view> -->
	<keep-alive :include="['MyComponent']">
		<router-view v-if="$route.meta.keepAlive" />
	</keep-alive>
	<router-view v-if="!$route.meta.keepAlive" />
</template>

<script>
	export default {
		name: 'App'
	}
</script>

<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #2c3e50;
	}
	
	.van-dialog__header {
		font-size: 26px;
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	.van-dialog__footer,
	.van-button__content {
		height: 80px;
		font-size: 28px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.van-dialog__confirm,
	.van-dialog__cancel {
		height: 80px;
	}
</style>
