import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index.js'
import axios from './utils/axios.js'
import Vant from 'vant';
// import ElementPlus from 'element-plus';
import 'vant/lib/index.css';
// import 'element-plus/theme-chalk/index.css';
import 'amfe-flexible';
// import locale from 'element-plus/lib/locale/lang/zh-cn'
import wx from "weixin-js-sdk";
  import vue3SeamlessScroll from "vue3-seamless-scroll";
import { Tab, Tabs,Sticky,Field,RadioGroup, Radio,Overlay,Toast,Switch,Swipe, SwipeItem,NoticeBar,SwipeCell,Checkbox,Popup,Calendar,DropdownMenu, DropdownItem,PullRefresh,List,Dialog,Circle,Icon,Badge } from 'vant';
var app = createApp(App)
app.use(router).use(Vant).use(Tab).use(Tabs).use(Sticky).use(Field).use(RadioGroup).use(Radio).use(Overlay).use(Toast).use(Switch).use(Swipe).use(SwipeItem).use(NoticeBar).use(SwipeCell).use(Checkbox).use(Popup).use(Calendar).use(DropdownMenu).use(DropdownItem).use(List).use(PullRefresh).use(Dialog).use(Circle).use(Icon).use(Badge).use(vue3SeamlessScroll).mount('#app')
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$wxsdk = wx

