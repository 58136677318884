import https from '../../http.js'

// 系统设置
export function setweb(data) {
	return https.post('/index/web', data,1)
}
// 授权
export function gettoken(data) {
	return https.post('/token/getToken', data,1)
}
//首页
export function homedatas(data) {
	return https.post('/index/homedata', data,1)
}
//学校
export function homeschool(data) {
	return https.post('/index/school', data,1)
}
//进入小程序
export function homescheme(data) {
	return https.post('/index/scheme', data,1)
}
//最新发布
export function circlebtn(data) {
	return https.post('/index/clist', data,1)
}
//公告详情
export function advert(data) {
	return https.post('/index/advert_detail', data,1)
} 
//个人中心
export function users(data) {
	return https.post('/users/user', data,1)
}
//积分排行榜
export function usersrank(data) {
	return https.post('/users/urank', data,1)
}
//我的关注
export function usersmyattent(data) {
	return https.post('/users/myAttent', data,1)
}
//我的粉丝
export function usersmyfans(data) {
	return https.post('/users/myFans', data,1)
}
//移除粉丝
export function usersremovefans(data) {
	return https.post('/users/removeFans', data,1)
}
//积分明细
export function userscoins(data) {
	return https.post('/users/coins', data,1)
}
//查询交友信息
export function usersprofile(data) {
	return https.post('/users/profile', data,1)
}
//提交交友信息
export function usersaveprofile(data) {
	return https.post('/users/save_profile', data,1)
}
//提现明细
export function cashucash(data) {
	return https.post('/cash/ucash_list', data,1)
}
//提现界面
export function cashinfo(data) {
	return https.post('/cash/cashInfo', data,1)
}
//开始提现
export function cashlist(data) {
	return https.post('/cash/ucash', data,1)
}
//发布帖子
export function circlepublish(data) {
	return https.post('/circle/publish', data,1)
}
//我的发布
export function circlelist(data) {
	return https.post('/circle/circle_list', data,1)
}
//捞个好友
export function usercrony(data) {
	return https.post('/users/crony', data,1)
}
//帖子详情
export function circleinfo(data) {
	return https.post('/circle/detailInfo', data,1)
}
//帖子详情评论数据
export function circlelistcommt(data) {
	return https.post('/circle/list_commt', data,1)
}
//评论
export function circlecommt(data) {
	return https.post('/circle/commt', data,1)
}
//子评论头部
export function circlecommtinfo(data) {
	return https.post('/circle/child_info', data,1)
}
//子评论列表
export function circlecommtlist(data) {
	return https.post('/circle/child_list', data,1)
}
//帖子分类
export function circlescate(data) {
	return https.post('/circle/scate', data,1)
}
//删除回复
export function cirledetle(data) {
	return https.post('/circle/delete_commt', data,1)
}
//关注/取消关注
export function userayyent(data) {
	return https.post('/users/attent', data,1)
}
//上传照片
export function uploadoss(data) {
	return https.post('/uploads/ossindex', data,1)
}
//提现详情
export function cashsuccc(data) {
	return https.post('/cash/cashSucc', data,1)
}
//编辑帖子
export function editcircle(data) {
	return https.post('/circle/publishEdit', data,1)
}
//删除帖子
export function delcircle(data) {
	return https.post('/circle/delete', data,1)
}
//删除图片
export function delpic(data) {
	return https.post('/circle/delpic', data,1)
}
//单个学校信息
export function indexinfo(data) {
	return https.post('/index/schoolInfo', data,1)
}
//首页公告
export function indexhomlep(data) {
	return https.post('/index/homelep', data,1)
}
//判断老好友
export function usercheck(data) {
	return https.post('/users/check_crony', data,1)
}
//帖子排行榜
export function cirrank(data) {
	return https.post('/circle/rank', data,1)
}
//是否签到/浏览
export function checkdata(data) {
	return https.post('/users/check_day', data,1)
}
//浏览给积分
export function userbrows(data) {
	return https.post('/users/browse', data,1)
}
//签到给积分
export function usersign(data) {
	return https.post('/users/sign', data,1)
}
//跳转到小程序
export function homeIndex(data) {
	return https.post('/index/homeIndex', data,1)
}
//是否关注
export function isfollow(data) {
	return https.post('/users/isfollow', data,1)
}
//点赞
export function praise(data) {
	return https.post('/circle/praise', data,1)
}
//消息详情
export function maildetail(data) {
	return https.post('/mail/detail', data,1)
}
//消息列表
export function maillist(data) {
	return https.post('/mail/list', data,1)
}
//拼多多列表
export function pluglist(data) {
	return https.post('/plug/clist', data,1)
}
//拼多多详情
export function plugdetail(data) {
	return https.post('/plug/detail', data,1)
}
//奖品列表
export function drawlist(data) {
	return https.post('/draw/list', data,1)
}
//抽奖记录
export function drawrecord(data) {
	return https.post('/draw/record', data,1)
}
//我的奖品
export function drawpiece(data) {
	return https.post('/draw/piece', data,1)
}
//我的订单
export function list_order(data) {
	return https.post('/draw/list_order', data,1)
}
//订单详情
export function order_detail(data) {
	return https.post('/draw/order_detail', data,1)
}
//地址列表
export function addressList(data) {
	return https.post('/address/addressList', data,1)
}
//结算页
export function settle(data) {
	return https.post('/draw/settle', data,1)
}
//添加
export function editAddress(data) {
	return https.post('/address/editAddress', data,1)
}
//下单
export function single_order(data) {
	return https.post('/draw/single_order', data,1)
}
//抽奖
export function raffle(data) {
	return https.post('/draw/raffle', data,1)
}





//登录
export function indexlogin(data) {
	return https.post('/index/login', data,2)
}
//首页
export function indexhome(data) {
	return https.post('/index/homedata', data,2)
}
//公告列表
export function indexmadvert(data) {
	return https.post('/index/madvert', data,2)
}
//帖子分类
export function shopscate(data) {
	return https.post('/shop/scate', data,2)
}
//帖子列表
export function shopcircle(data) {
	return https.post('/shop/circleList', data,2)
}
//帖子详情
export function shopcircleinfo(data) {
	return https.post('/shop/circleInfo', data,2)
}
//个人信息
export function shopindex(data) {
	return https.post('/shop/index', data,2)
}
//详情页评论
export function shopcommt(data) {
	return https.post('/shop/list_commt', data,2)
}
//删除回复
export function shopdelecommt(data) {
	return https.post('/shop/delete_commt', data,2)
}
//子评论头部
export function shopchuildinfo(data) {
	return https.post('/shop/child_info', data,2)
}
//系统设置
export function setwebs(data) {
	return https.post('/index/web', data,2)
}
//绑定微信
export function wxpay(data) {
	return https.post('/shop/wechat', data,2)
}
//添加/编辑公告
export function modifyedit(data) {
	return https.post('/index/modifyEdit', data,2)
}
//公告详情
export function advertdetail(data) {
	return https.post('/index/advert_detail', data,2)
}
//删除公告
export function modifydelete(data) {
	return https.post('/index/modifyDelete', data,2)
}
//修改学校信息
export function shopperdect(data) {
	return https.post('/shop/perfect', data,2)
}
//子评论列表
export function shoplist(data) {
	return https.post('/shop/child_list', data,2)
}
//审核帖子
export function shopexam(data) {
	return https.post('/shop/exam', data,2)
}
//删除图片
export function indexdelpic(data) {
	return https.post('/index/delpic', data,2)
}
// jsdk认证
export function jsdk(data) {
	return https.post('https://xbxy.xiaobiaopaotui.com/api/auth/jsauth', data)
}
// 海报
export function poster(data) {
	return https.post('/users/poster', data,1)
}
//置顶
export function indextop(data) {
	return https.post('/index/top', data,2)
}



